<template>
  <div class="about">
    <!-- p1 -->
    <section class="about-section-1">
      <img class="sec-1-img-1" src="../../assets/images/words@2x.png" alt="">
      <img class="sec-1-img-2" src="../../assets/images/about-logo@2x.png" alt="">
      <div class="sec-1-content animate__animated animate__fadeInDown">
        <div class="sec-1-content-detail">
          <p>{{ $t('about.msg_1') }}</p>
        </div>
      </div>
    </section>
    <!-- p2 -->
    <section id="about-section-2" class="about-section-2">
      <div class="sec-2-word">
        <p>{{ $t('about.msg_2') }}</p>
        <p>{{ $t('about.msg_3') }}</p>
      </div>
      <div class="sec-2-content">
        <div id="sec-2-content-title" class="sec-2-content-title animate__animated monkey-hide">
          <p>
            <span class="sec-2-content-title-icon">&lt;</span>
            {{ $t('about.msg_4') }}
            <span class="sec-2-content-title-icon">&gt;</span>
          </p>
        </div>
        <div class="sec-2-content-detail">
          <p>{{ $t('about.msg_5') }}</p>
        </div>
      </div>
    </section>
    <!-- p3 -->
    <section id="about-section-3" class="about-section-3">
      <div class="sec-3-content">
        <div id="sec-3-content-title" class="sec-3-content-title animate__animated monkey-hide">
          <p>
            <span class="sec-3-content-title-icon">&lt;</span>
            {{ $t('about.msg_6') }}
            <span class="sec-3-content-title-icon">&gt;</span>
          </p>
        </div>
        <div class="sec-3-content-detail">
          <p>{{ $t('about.msg_7') }}</p>
        </div>
      </div>
    </section>
    <!-- p4 -->
    <section id="about-section-4" class="about-section-4">
      <div class="sec-4-content">
        <div id="sec-4-content-title" class="sec-4-content-title animate__animated monkey-hide">
          <p>
            <span class="sec-4-content-title-icon">&lt;</span>
            {{ $t('about.msg_8') }}
            <span class="sec-4-content-title-icon">&gt;</span>
          </p>
        </div>
        <div class="sec-4-content-detail">
          <p>{{ $t('about.msg_9') }}</p>
        </div>
      </div>
    </section>
    <!-- 间隔 -->
    <div class="about-space">
      <img class="about-space-img-1" src="../../assets/images/about-deco_04@2x.png" alt="">
      <img class="about-space-img-2" src="../../assets/images/phone-about-deco_04@2x.png" alt="">
    </div>
    <!-- p5 -->
    <section class="about-section-5">
      <!-- item 1 -->
      <div class="sec-5-item">
        <div class="sec-5-item-title">
          {{ $t('about.msg_10') }}
        </div>
        <div class="sec-5-item-img">
          <img src="../../assets/images/about-left@2x.png" alt="">
        </div>
        <div class="sec-5-item-content">
          <div class="sec-5-item-content-title">
            <p>{{ $t('about.msg_11') }}</p>
          </div>
          <div class="sec-5-item-content-text">
            <p>{{ $t('about.msg_12') }}</p>
          </div>
        </div>
      </div>
      <!-- item 2 -->
      <div class="sec-5-item">
        <div class="sec-5-item-title">
          {{ $t('about.msg_13') }}
        </div>
        <div class="sec-5-item-img">
          <img src="../../assets/images/about-middle@2x.png" alt="">
        </div>
        <div class="sec-5-item-content">
          <div class="sec-5-item-content-title">
            <p>{{ $t('about.msg_14') }}</p>
          </div>
          <div class="sec-5-item-content-text">
            <p>{{ $t('about.msg_15') }}</p>
          </div>
        </div>
      </div>
      <!-- item 3 -->
      <div class="sec-5-item">
        <div class="sec-5-item-title">
          {{ $t('about.msg_16') }}
        </div>
        <div class="sec-5-item-img">
          <img src="../../assets/images/about-right@2x.png" alt="">
        </div>
        <div class="sec-5-item-content">
          <div class="sec-5-item-content-title">
            <p>{{ $t('about.msg_17') }}</p>
          </div>
          <div class="sec-5-item-content-text">
            <p>{{ $t('about.msg_18') }}</p>
          </div>
        </div>
      </div>
      <div class="clear-block"></div>
    </section>
  </div>
</template>
<script>
export default {
  data () {
    return {
      p2Action: false,
      p3Action: false,
      p4Action: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.viewScroll, true)
  },
  methods: {
    viewScroll () {
      // p2动画效果
      const screenHeight = window.screen.height
      const screenWidth = window.screen.width
      const isSmall = screenWidth < 800

      const watchDom1 = document.getElementById('about-section-2')
      const height1 = watchDom1.getBoundingClientRect().top

      if (height1 < screenHeight / 2 && !this.p2Action) {
        const dom1 = document.getElementById('sec-2-content-title')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__backInRight')
        }
        this.p2Action = true
      }
      // p3动画效果
      const watchDom3 = document.getElementById('about-section-3')
      const height3 = watchDom3.getBoundingClientRect().top
      if (height3 < screenHeight / 2 && !this.p3Action) {
        const dom1 = document.getElementById('sec-3-content-title')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__backInRight')
        }
        this.p3Action = true
      }
      // p3动画效果
      const watchDom4 = document.getElementById('about-section-4')
      const height4 = watchDom4.getBoundingClientRect().top
      if (height4 < screenHeight / 2 && !this.p4Action) {
        const dom1 = document.getElementById('sec-4-content-title')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__backInRight')
        }
        this.p4Action = true
      }
    },
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .about {
    // p1 css
    .about-section-1 {
      height: 14.39rem;
      background: url('../../assets/images/about-banner@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .42rem;
        left: 1.51rem;
      }
      .sec-1-img-2 {
        width: 5.75rem;
        height: 1.2rem;
        position: absolute;
        top: 5.52rem;
        left: 4.25rem;
      }
      
      .sec-1-content {
        position: absolute;
        top: 4.69rem;
        left: 11.12rem;
        width: 5.49rem;

        .sec-1-content-detail {
          padding-top: .34rem;
          width: 5.2rem;
          font-size: .16rem;
          color: #394149;
          line-height: .42rem;
        }
      }
    }
    // p2 css
    .about-section-2 {
      margin-top: .6rem;
      height: 7.91rem;
      background: url('../../assets/images/about-p2_pic@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-2-word {
        color: #F2F3F4;
        font-size: .28rem;
        text-align: center;
        line-height: .6rem;
        position: absolute;
        top: -.6rem;
        left: 50%;
        margin-left: -50%;
        display: block;
        width: 100%;
      }

      .sec-2-content {
        position: absolute;
        top: 2.55rem;
        width: 100%;
        left: 50%;
        margin-left: -50%;
        text-align: center;

        .sec-2-content-title {
          color: #000;
          font-size: .48rem;
          font-family: 'SourceHanSansCNBold';
        }

        .sec-2-content-detail {
          text-align: center;
          padding-top: .34rem;
          font-size: .28rem;
          color: #40444A;
          line-height: .42rem;
        }

      }
    }
    // p3 css
    .about-section-3 {
      margin-top: .6rem;
      height: 7.61rem;
      background: url('../../assets/images/about-p3_pic@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-3-content {
        position: absolute;
        top: 2.55rem;
        width: 100%;
        left: 50%;
        margin-left: -50%;
        text-align: center;

        .sec-3-content-title {
          color: #000;
          font-size: .48rem;
          font-family: 'SourceHanSansCNBold';
        }

        .sec-3-content-detail {
          text-align: center;
          padding-top: .34rem;
          font-size: .28rem;
          color: #40444A;
          line-height: .42rem;
        }

      }
    }
    // p4 css
    .about-section-4 {
      margin-top: .6rem;
      height: 7.17rem;
      background: url('../../assets/images/about-p4_pic@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-4-content {
        position: absolute;
        top: 1.81rem;
        width: 100%;
        left: 50%;
        margin-left: -50%;
        text-align: center;

        .sec-4-content-title {
          color: #000;
          font-size: .48rem;
          font-family: 'SourceHanSansCNBold';
        }

        .sec-4-content-detail {
          text-align: center;
          padding-top: .34rem;
          font-size: .28rem;
          color: #40444A;
          line-height: .42rem;
        }

      }
    }
    // space
    .about-space {
      width: 100%;
      height: 3.14rem;
      position: relative;

      .about-space-img-1 {
        width: 4.66rem;
        height: .93rem;
        position: absolute;
        left: 2.88rem;
        top: 1.02rem;
      }
      .about-space-img-2 {
        display: none;
      }
    }
    // p5 css
    .about-section-5 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .sec-5-item {
        flex: 1;

        .sec-5-item-title {
          text-align: center;
          height: 1.1rem;
          line-height: 1.1rem;
          color: #C0C2C4;
          font-size: .28rem;
        }

        .sec-5-item-img {
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        .sec-5-item-content {
          .sec-5-item-content-title {
            display: none;
          }
          .sec-5-item-content-text {
            font-size: .16rem;
            color: #EBEAEA;
            width: 2.74rem;
            margin: .45rem auto .96rem auto;
            line-height: .28rem;
          }
        }
      }

    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .about {
    // p1 css
    .about-section-1 {
      width: 7.5rem;
      height: 12.34rem;
      background: url('../../assets/images/phone-about-p1_pic@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .12rem;
        left: .4rem;
      }
      .sec-1-img-2 {
        width: 5.75rem;
        height: 1.2rem;
        position: absolute;
        top: 2.05rem;
        left: .88rem;
      }
      
      .sec-1-content {
        position: absolute;
        top: 3.94rem;
        left: .89rem;
        width: 5.74rem;

        .sec-1-content-detail {
          padding-top: .34rem;
          width: 5.2rem;
          font-size: .22rem;
          color: #394149;
          line-height: .48rem;
        }

      }
    }
   // p2 css
    .about-section-2 {
      margin-top: .6rem;
      width: 7.5rem;
      height: 7.98rem;
      background: url('../../assets/images/phone-about-pic_1@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-2-word {
        color: #F2F3F4;
        font-size: .22rem;
        text-align: center;
        line-height: .6rem;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -50%;
        display: block;
        width: 100%;
      }

      .sec-2-content {
        position: absolute;
        top: 2.55rem;
        width: 100%;
        left: 50%;
        margin-left: -50%;
        text-align: center;

        .sec-2-content-title {
          color: #000;
          font-size: .28rem;
          font-family: 'SourceHanSansCNBold';
        }

        .sec-2-content-detail {
          text-align: center;
          padding-top: .34rem;
          font-size: .22rem;
          color: #40444A;
          line-height: .42rem;
        }
      }
    }
    // p3 css
    .about-section-3 {
      margin-top: .6rem;
      width: 7.5rem;
      height: 7.41rem;
      background: url('../../assets/images/phone-about-pic_2@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-3-content {
        position: absolute;
        top: 2.05rem;
        width: 100%;
        left: 50%;
        margin-left: -50%;
        text-align: center;

        .sec-3-content-title {
          color: #000;
          font-size: .28rem;
          font-family: 'SourceHanSansCNBold';
        }

        .sec-3-content-detail {
          text-align: center;
          padding-top: .34rem;
          font-size: .22rem;
          color: #40444A;
          line-height: .42rem;
        }
      }
    }
    // p4 css
    .about-section-4 {
      margin-top: .6rem;
      width: 7.5rem;
      height: 7.41rem;
      background: url('../../assets/images/phone-about-pic_3@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-4-content {
        position: absolute;
        top: 2.06rem;
        width: 100%;
        left: 50%;
        margin-left: -50%;
        text-align: center;

        .sec-4-content-title {
          color: #000;
          font-size: .28rem;
          font-family: 'SourceHanSansCNBold';
        }

        .sec-4-content-detail {
          text-align: center;
          padding-top: .34rem;
          font-size: .22rem;
          color: #40444A;
          line-height: .42rem;
        }
      }
    }
    // space
    .about-space {
      width: 100%;
      height: 3.29rem;
      position: relative;

      .about-space-img-1 {
        display: none;
      }
      .about-space-img-2 {
        position: absolute;
        width: 1.9rem;
        height: .8rem;
        top: 1.16rem;
      }
    }
    // p5 css
    .about-section-5 {
      padding: 0 .2rem 1.53rem 0;

      .sec-5-item {
        height: 5.06rem;
        margin-top: .28rem;

        .sec-5-item-title {
          display: none;
        }

        .sec-5-item-img {
          width: 3.6rem;
          height: auto;
          float: left;
          img {
            width: 100%;
            height: auto;
          }
        }
        .sec-5-item-content {
          float: left;
          width: 2.78rem;
          margin-left: .69rem;

          .sec-5-item-content-title {
            font-size: .26rem;
            color: #F0F2F4;
            font-family: 'SourceHanSansCNBold';
            line-height: .4rem;
          }
          .sec-5-item-content-text {
            font-size: .22rem;
            margin-top: .3rem;
            color: #C0C2C4;
            line-height: .46rem;
          }
        }
      }

    }
  }
}
</style>